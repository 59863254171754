import { auth, analytics } from '../fbInstance';
import { logEvent } from "firebase/analytics";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { firestore } from '../fbInstance';
import { doc, getDocs, collection, query, where } from "firebase/firestore";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import CustomUI from '../confirm/confirmSigout.js';
import axios from "axios"
import "./loginMainPage.css";
import Loading from "../auth/component/loadingSigoutProgressComponent.js";
import { useTranslation } from "react-i18next";

const STATUS_WAITING = "waiting"
const STATUS_LINKED = "linked"
const STATUS_CANCELED = "canceled"
const STATUS_UNLINKED = "unlinked"
const STATUS_DELETED = "deleted"

const LoginMainPage = () => {

    const { t } = useTranslation();

    const userDatas = useLocation();

    const [connectionState, setConnectionState] = useState(false); //연결 상태
    const [isSigoutAgree, setSigoutAgree] = useState(false); //동의 여부 상태
    const [isSigoutLoading, setSigoutLoading] = useState(false); //탈퇴 진행시 프로그래스바 

    const confirmSigout = () => {
        if (!isSigoutAgree) return
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <CustomUI onClose={onClose} onPositive={onClickRequestSignout} />
                );
            }
        });
    };


    const displayName = userDatas.state?.displayName

    var name = null;
    if (displayName != null) {
        if (window.navigator.language == "ko-KR") {
            name = displayName + t('login_main_confirm_user1');
        } else {
            name = t('login_main_confirm_user1') + displayName + ",";
        }
    }

    const userId = userDatas.state?.userId
    const roomId = userDatas.state?.roomId

    const link_login_page = `${process.env.REACT_APP_BASE_URL}/`
    const link_login_error = `${process.env.REACT_APP_BASE_URL}/error/loginErrorPage`
    const link_sigout_complete = `${process.env.REACT_APP_BASE_URL}/auth/sigoutComplete`
    const link_sigout_failed = `${process.env.REACT_APP_BASE_URL}/error/sigoutFailedPage`


    const navigate = useNavigate();


    function onClickRequestSignout() {
        setSigoutLoading(true)
        if (connectionState) {
            requestDisconnect()
        } else {
            requestAccountDelete()
        }
    }

    function requestDisconnect() {
        axios.put(
            "https://us-central1-thedaycouple.cloudfunctions.net/connection/unlink",
            {
                mode: "live",
                userId: userId,
                roomId: roomId
            },
            { headers: { "Content-type": "application/x-www-form-urlencoded;charset=utf-8" } }
        ).then((result) => {
            requestAccountDelete()
        })
            .catch((error) => {
                navigate(
                    link_sigout_failed,
                )
                setSigoutLoading(false)
            })
    }

    function requestAccountDelete() {
        axios.delete(
            `https://asia-northeast1-thedaycouple.cloudfunctions.net/admin/v2/delete/${userId}`,
            {
                data: {
                    mode: "live"
                }
            },
            { headers: { "Content-type": "application/x-www-form-urlencoded;charset=utf-8" } }
        ).then((result) => {

            logEvent(analytics, 'web_withdrawal_success', { name: 'couple_web_withdrawal_success' });
            navigate(
                link_sigout_complete,
            )
            setSigoutLoading(false)
        })
            .catch((error) => {
                console.log("탈퇴 실패", error);
                navigate(
                    link_sigout_failed,
                )
                setSigoutLoading(false)
            })
    }

    async function fetchData() {

        try {
            var connectionQuery = query(collection(firestore, 'connections'), where('roomId', '==', roomId))
            const connection = await getDocs(connectionQuery);
            var connectionData = null

            connection.forEach((doc) => {
                connectionData = doc.data();
            });
            if (connectionData != null) {

                var { status } = connectionData;

                if (status == STATUS_CANCELED) {
                    setConnectionState(false);
                } else {
                    setConnectionState(true);
                }
            }



        } catch (error) {
            navigate(
                link_login_error,
            )
        }

    }

    const onClickSigoutAgree = () => { //체크박스 색상을 위한 동의 여부 변수
        if (isSigoutAgree) {
            setSigoutAgree(false)
        } else {
            setSigoutAgree(true)
        }
    }

    useEffect(() => {

        fetchData()

    }, [])

    return (
        <>
            {isSigoutLoading ?
                <div className="divMainLogin">
                    <Loading />
                </div>

                :

                <div className="divLoginMain">

                    <section className="parentLoginMain">
                        <div tyle="float: left;">
                            <div className="div3LoginMain">
                                <div className="div1LoginMain">
                                    <p className="pLoginMain">{displayName ? name : null}</p>
                                    <p className="p1LoginMain">{t('login_main_confirm_user')}</p>
                                </div>
                            </div>
                            <div className="div2LoginMain">
                                <p className="p2LoginMain">·</p>
                                <div className="button-parentLoginMain">
                                    {t('login_main_confirm_message_1')}
                                </div>
                            </div>
                            <div className="div2LoginMain">
                                <p className="p2LoginMain">·</p>
                                <div className="button-parentLoginMain">
                                    {t('login_main_confirm_message_2')}
                                </div>
                            </div>
                            <div className="div2LoginMain">
                                <p className="p2LoginMain">·</p>
                                <div className="button-parentLoginMain">
                                    {t('login_main_confirm_message_3')}
                                </div>
                            </div>
                            <div className="div2LoginMain">
                                <p className="p2LoginMain">·</p>
                                <div className="button-parentLoginMain">
                                    {t('login_main_confirm_message_4')}
                                </div>
                            </div>
                        </div>

                        <div className="div2LoginMain" onClick={onClickSigoutAgree}>
                            <img
                                className="ic-radio-checkbox-off-iconLoginMain"
                                loading="lazy"
                                alt=""
                                src={isSigoutAgree ? process.env.PUBLIC_URL + '/assets/ic_radio_checkbox_on.svg' : process.env.PUBLIC_URL + '/assets/ic_radio_checkbox_off.svg'}
                            />
                            <div className="text-label4LoginMain">{t('login_main_confirm_message_confirm')}</div>
                        </div>
                    </section>
                    <div className='divBoxButtonLogin'>
                        <button className="container-button-groupLoginMain" onClick={confirmSigout}>
                            <div className="button-button-1LoginMain">
                                <div className={isSigoutAgree ? "container3OnLoginMain" : "container3LoginMain"}>
                                    <div className={isSigoutAgree ? "text-label3OnLoginMain" : "text-label3LoginMain"}>{t('login_main_delete_button')}</div>
                                </div>
                            </div>
                        </button>
                    </div>
                </div>
            }
        </>
    )
}
export default LoginMainPage